import actions from './actions'

const initialState = {
  menuLeftData: [],
  menuTopData: [],
  menuComputeEngineData: [],
  menuEc2Data: [
    {
      title: 'CE Dashboard',
      key: 'ce',
      url: '/ce',
    },
    {
      divider: true,
    },
    {
      title: 'Instances',
      key: 'instances',
      children: [
        {
          title: 'Instances',
          url: '/ce/instances',
          key: 'ins',
        },
        {
          title: 'Instance Types',
          url: '/ce/instances/types',
          key: 'instance-types',
        },
      ],
    },
    {
      title: 'Elastic Block Store',
      key: 'block-store',
      children: [
        {
          title: 'Volumes',
          url: '/ce/elastic-block-store/volumes',
          key: 'volumes',
        },
        {
          title: 'Snapshots',
          url: '/ce/elastic-block-store/snapshots',
          key: 'snapshots',
        },
        // {
        //   title: 'VM Snapshots',
        //   url: '/ce/elastic-block-store/vm-snapshots',
        //   key: 'vm-snapshots',
        // },
      ],
    },
    {
      title: 'Network & Security',
      key: 'network-security',
      hideChildren: true,
      children: [
        // {
        //   title: 'VPC',
        //   url: '/ce/network/vpc',
        //   key: 'vpc',
        // },
        {
          title: 'Security Groups',
          url: '/ce/network/security-groups',
          key: 'security-groups',
        },
        // {
        //   title: 'Elastic IPs',
        //   url: '/ce/network/elastic-ips',
        //   key: 'elastic-ips',
        // },
        // {
        //   title: 'ACL lists',
        //   url: '/ce/network/acl-lists',
        //   key: 'acl-lists',
        // },
        {
          title: 'Key Pairs',
          key: 'key-pairs',
          url: '/ce/keypair',
        },
      ],
    },
  ],
  isMenuOpen: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
