import { all } from 'redux-saga/effects'
import menu from './menu/sagas'
import settings from './settings/sagas'
import workspaces from './workspaces/sagas'
import zones from './zones/sagas'

export default function* rootSaga() {
  yield all([
    // user(),
    menu(),
    settings(),
    workspaces(),
    zones(),
  ])
}
