import React from 'react'
import { Col, Row } from 'antd'
import constants from 'web-constant'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ProfileMenu from './ProfileMenu'
import ProjectManagement from './ProjectManagement'
import MenuServices from './MenuServices'
import styles from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  showZone: settings.showZone,
  showServiceMenu: settings.showServiceMenu,
})

@withRouter
@connect(mapStateToProps)
class TopBar extends React.Component {
  render() {
    const { showZone = 1, showServiceMenu = 1 } = this.props
    return (
      <Row className={styles.topbar}>
        <Col span={12}>
          <Row>
            <Col className={styles.logo}>
              <a href={constants.DASHBOARD_CLIENT_URL}>
                <img src="/resources/images/epc-logo.png" alt="EPC" />
              </a>
            </Col>
            {showServiceMenu && (
              <Col className="mr-4">
                <MenuServices />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    )
  }
}

export default TopBar
