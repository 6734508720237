import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { PropTypes } from 'prop-types'
import DashboardLayout from './Dashboard'
import { setUserData } from '../../redux/user/actions'

const Layouts = {
  dashboard: DashboardLayout,
}

const mapStateToProps = state => ({
  setUserDataRedux: PropTypes.func.isRequired,
  user: state.user,
})
const dispatchStateToProps = dispatch => ({
  setUserDataRedux: data => dispatch(setUserData(data)),
})

@withRouter
@connect(mapStateToProps, dispatchStateToProps)
class IndexLayout extends React.PureComponent {
  constructor(props) {
    super(props)
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props
    const { prevLocation } = prevProps
    if (location !== prevLocation) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const { children } = this.props

    return (
      <Fragment>
        <Helmet titleTemplate="Engineering Platform | %s" title="Dashboard" />
        <Layouts.dashboard>{children}</Layouts.dashboard>
      </Fragment>
    )
  }
}

export default IndexLayout
