const port = process.env.REACT_APP_BACKEND_PORT || 4001
const isLocal = process.env.REACT_APP_CUSTOM_NODE_ENV === 'local'
const prefix = isLocal ? `http://localhost:${port}` : ''
const envUrl =
  process.env.REACT_APP_CUSTOM_NODE_ENV === 'production'
    ? ''
    : `${process.env.REACT_APP_CUSTOM_NODE_ENV}-`

export default {
  API_URL: `${prefix}/api/client`,
  AUTH_URL: `${prefix}/auth`,
  ID_URL: isLocal
    ? 'http://localhost:4001/auth/login'
    : `https://${envUrl}id.engineering.vng.vn/auth/login`,
  DASHBOARD_CLIENT_URL: `https://${envUrl}console.engineering.vng.vn/dashboard`,
  CE_CLIENT_URL: `https://${envUrl}ce.engineering.vng.vn/ce`,
  S3_CLIENT_URL: `https://${envUrl}s3.engineering.vng.vn/s3`,
  DF_CLIENT_URL: `https://${envUrl}mobilefarm.engineering.vng.vn`,
  EFS_CLIENT_URL: `https://${envUrl}efs.engineering.vng.vn/efs`,
  RDS_CLIENT_URL: `https://${envUrl}rds.engineering.vng.vn/rds`,
  CD_CLIENT_URL: `https://${envUrl}domain.engineering.vng.vn/domains`,
  MARKET_CLIENT_URL: `https://${envUrl}marketplace.engineering.vng.vn/domains`,
  // WS_CLIENT_URL: `https://${envUrl}www.team.vng.vn`,
}
