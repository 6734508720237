import React from 'react'
import styles from './style.module.scss'
import './style.overwrite.scss'

const Footer = () => (
  <div className={styles.footer}>
    <div className={styles.inner}>
      ©2021 made with <i className="fa fa-heart heart" /> by Engineering Platform
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <i className="fa fa-envelope-o" />
      &nbsp;
      <a href="mailto:ep@vng.com.vn" target="_blank" rel="noopener noreferrer">
        ep@vng.com.vn
      </a>
    </div>
  </div>
)

export default Footer
