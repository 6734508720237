export async function getLeftMenuData() {
  return []
}
export async function getTopMenuData() {
  return []
}
export async function getComputeEngineMenuData() {
  return []
}

export async function getEc2MenuData() {
  return []
}
